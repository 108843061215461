/***** LOGIN PAGE *****/
/************************/

.loginwrapper input#user_name { background: #eee url(/theme/img/username.png) no-repeat 8px 12px; }
.loginwrapper input#user_password, .loginwrapper input#user_email { background: #eee url(/theme/img/password.png) no-repeat 8px 12px; }
/*.loginwrapper input#user_email { background: url("../theme/img/e_mail.png") no-repeat scroll 8px 12px #EEEEEE;}*/
.loginwrapper input#new_pwd { background: #eee url(/theme/img/password.png) no-repeat 8px 12px; }
.loginwrapper input#confirm_pwd { background: #eee url(/theme/img/password.png) no-repeat 8px 12px; }

.loginwrapper input[type=text] { 
	width: 100%; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box;
	padding: 10px; min-height: 44px; font-size: 14px !important; border: 0; color: #999;
	background: #eee; padding-left: 30px; 
}

.loginwrapper input[type=submit] {
    -moz-box-sizing: border-box;
    background: linear-gradient(to bottom, #DC7505 0%, #C26704 100%) repeat scroll 0 0 transparent;
    border: 0 none;
    color: #FFFFFF;
    font-size: 14px !important;
    min-height: 44px;
    padding: 10px 10px 10px 10px;
    width: 100%;
}
.loginwrapper button.btn {
    font-size: 14px !important;
    min-height: 44px;
    padding: 10px 10px 10px 10px;
    width: 100%;
	text-align:center;
}
.success {
    border-color: #C7F2C8 !important;
    color: #33A333;
}
.error.message {
    background: none repeat scroll 0 0 #FFCCCC;
    border: 1px solid #DD7777;
    color: #EE5555;
}
.message {
    background-color: #FFFFFF;
    border: 1px solid #B8D3E5;
    font-size: 12px;
    height: auto;
    margin: 15px 238px;
    padding: 5px 10px;
    width: 880px;
}
.message ul {
    margin: 0;
    padding: 0;
}
.message li {
    list-style: square inside none;
    margin-left: 15px;
    padding: 0px;
	margin:3px 0px;
}
.validationMsgClose {
    color: red;
    left: 75%;
    position: relative;
    text-decoration: none;
}
.validationErrorMsgClose {
	color: red;
    left: 83%;
    position: absolute;
    text-decoration: none;
    top: 13%;
}

/**** for upcoming events *****/

/*
.viewUpcomingEvents{
	background-color: #F07918;
    height: auto;
    overflow: hidden;
    padding: 5px;
    text-align: center;
    width: 300px;
}
.eventDetails{
	background-color: #FFFFFF;
    padding: 5px;
}
.eventViewTitle{
	background-color: #D7DADC;
    padding: 5px;
}
.upcomingEvents{
	background-color: #D7DADC;
    height: auto;
    left: 38px;
    position: absolute;
    top: 60px;
    width: 310px;
}
*/

/************************* */

/*** for full event calendar ***/

#eventCalendar {
	/*background: none repeat scroll 0 0 #FFFFFF;
    border: medium solid #D7DADC;
    left: 410px;
    padding: 10px;
    position: absolute;
    top: 60px;
    width: 40;*/
}

#calendar {
	width: auto;
	margin: 0 auto;
}
	
.eventshow {
  position: relative;
  top: 0px;
  left: 1px;
  width:90%;
  height:30px;
  opacity:0.8;
  filter:alpha(opacity=80);
  color: #ffffff;
  background-color: #000;
  padding:10px 25px 10px 25px;
  z-index:999;
  border-color: #fff;
  border-radius:5px; 
  -moz-border-radius:5px;
  -webkit-border-radius:5px;
}

/************************** ***/

.search_grid_btn {
	background: none repeat scroll 0 0 #F07918;
    border: medium none;
    float: right;
    height: 29px;
    margin-left: 5px;
    margin-top: 0px;
    width: 36px;
}

.pkg_label {
	color:yellow;
}
.limit_label {
	color:green;
}
.price_text_box {
	color:purple;
}

.dashboard .widget .heading, #quotation-invoice-activity .widget .heading, #commentlist .widget .heading{padding-left:0px !important;}

.managecompany .widget .btn{width:160px;}
.innerLR .widget .searchbtn{
float: none;height: 30px;margin-left: 5px;margin-top:2px;width: 32px;margin-bottom:9px;
}

.upgradePkg {margin-top:15px;}
.upgradePkg .widget .heading{padding-left:0px !important;}
.upgradePkg [class*="span"], .upgradePkg .uneditable-input[class*="span"], .upgradePkg .row-fluid [class*="span"] {width:99%;}
.upgradePkg .widget .widget-body #packageId{width:50%;}
.upgradePkg .widget .widget-body .current-pkg-label{ text-align: left; float: left;padding-top: 5px;text-align: right; }

.manageAdmin .widget .addAdminBtn{width:160px;}
.manageAdmin .widget .addAdminBtnSpan{float:left; font-weight:bold; margin-top:11px; text-transform:uppercase;}

.adminDetail .widget .heading{padding-left:0px !important;}

.companyDetail .widget .heading{padding-left:0px !important;}

.managePkg .widget .heading{padding-left:0px !important;}
.managePkg .widget .pkg-labels{position:relative;margin-left:170px;}
.managePkg .widget .pkg-label{display:inline-block;width:12%;position:relative;color:#FFFFFF;cursor: default;background-color:#F07918;padding:10px;padding-right:13px;cusror:default;border:1px #CCCCCC solid;border-radius:5px;text-align:center;}
.managePkg .widget .limit_label{background-color: #F07918;color: #FFFFFF;float: left;
    margin-left: 25px;
    margin-right: 15px;
    margin-top: 25px;
    padding: 5px;
    width: 11%;
    cursor: default;
	border:1px #CCCCCC solid;border-radius:5px;
	text-align:center;
}
.managePkg .widget #price{position:relative;margin-left:150px;}
.managePkg .widget .price_text_box{position:relative;margin-top:25px;}

.manageAddon .widget .heading{padding-left:0px !important;}
/*.manageAddon .widget #addon-list{position:relative;margin-left:150px;}*/
.manageAddon .widget .addon-label{width:25%;/*position:relative;*/color:#FFFFFF;cursor: default;background-color:#F07918;padding:10px;padding-right:13px;cusror:default;border:1px #CCCCCC solid;border-radius:5px;}
.manageAddon .widget .addon_input_label{background-color: #F07918;
    color: #FFFFFF;
    cursor: default;
    float: left;
    padding: 5px;
    /*position: relative;
    right: 119px;*/
    width: 100px;
	border:1px #CCCCCC solid;border-radius:5px;
}

.manageAddon .widget .addon_input_text{position:relative;right:109px;}

#eventCalendar .fc-event-title-with-new-css{/*background-color: #F07918;border-color: #F07918;*/color: #FFFFFF;text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);padding:0 5px 0 0px;}
.fc-event-inner{padding:0px 0px 0px 5px;cursor:pointer;}

.notyfy_custom_msg.success{background: none repeat scroll 0 0 green;border: medium none;}
.notyfy_custom_msg.fail{background: none repeat scroll 0 0 red;border: medium none;}
.remember-me-input {
    background: none repeat scroll 0 0 #EEEEEE !important;
    border: solid !important;
    color: #999999 !important;
    font-size: 14px !important;
    width: auto !important;
    height: auto !important;
}
.remember-me-text {
	position: relative;
	margin: 5px;
	color:#999999;
}
.remember-me-label { padding-left:0px; }

.alert.alert-error ul{list-style-type:square; margin-bottom:0px;}
.loginwrapper .alert.alert-error ul{list-style-type:square; margin-bottom:0px;padding:5px;}

#menu .profile{padding:0px;padding-top:25px;padding-bottom:10px;text-align:center;}
.line-through {text-decoration: line-through;}

.viewtask .widget #commentlist h4, .viewAction .widget #commentlist h4{padding-left:5px;}

/*.viewtask .widget #mark_completion_form #mark_as_completed_btn{position: relative;left: 351px;top: -32px;}
.viewtask .widget #mark_completion_form #mark_as_incompleted_btn{position: relative;left: 351px;top: -32px;}

.viewtask .widget .complete-add-comment{position: relative;left: 370px;top: -64px;}
.viewtask .widget .incomplete-add-comment{position: relative;left: 381px;top: -64px;}*/

#search{padding-top:3px;}
#search a{position:relative;top:-3px;}

.amber, .amber td{background:#FFFF66 !important;}
.amber a, .amber td a{color:#333333 !important;}

.dark-red, .dark-red td{background:#FA8072 !important; /* FF0000 */}
.dark-red a, .dark-red td a{color:#333333 !important;}

.navbar.main .topnav > li.open .dropdown-menu li > a, .navbar.main .topnav > li .notif li.open .dropdown-menu li > a, .navbar.main .topnav > li.open .dropdown-menu li > a:hover, .navbar.main .topnav > li .notif li.open .dropdown-menu li > a:hover, .navbar.main .topnav > li.open .dropdown-menu li > span, .navbar.main .topnav > li .notif li.open .dropdown-menu li > span {
   padding-left:35px;
}

.navbar.main .topnav > li.open .dropdown-menu li > a.glyphicons i:before, .navbar.main .topnav > li .notif li.open .dropdown-menu li > a.glyphicons i:before, .navbar.main .topnav > li.open .dropdown-menu li > a.glyphicons:hover i:before, .navbar.main .topnav > li .notif li.open .dropdown-menu li > a.glyphicons:hover i:before, .navbar.main .topnav > li.open .dropdown-menu li > span.glyphicons i:before, .navbar.main .topnav > li .notif li.open .dropdown-menu li > span.glyphicons i:before {
  left: 10px;
  top: 10px;
}

.navbar.main .topnav > li.open .pull-left, .navbar.main .topnav > li .notif li.open .pull-left{
width:auto; right:auto;
}
.navbar.main .topnav > li.open .pull-right, .navbar.main .topnav > li .notif li.open .pull-right{
width:182px;
right:0px;
}

.underline{text-decoration:underline;}

.accessdenied .widget .heading{padding-left:0px !important;}

#view_task_detail .controls, #view_my_registrant_detail .controls { padding-top: 5px;}
#view_task_detail .control-label, #view_event_detail .control-label, #view_my_action_detail .control-label, #view_my_registrant_detail .control-label{ font-weight: bolder;}

#view_quotation_detail .controls { padding-top: 5px;}
#view_quotation_detail .control-label { font-weight: bolder;}

iframe{/*border:1px solid #C1C1C1;*/ border:none;}

.document_size_details a{font-size:x-large;text-decoration:none;cursor: default;}

.createFolder .widget .heading , .uploadFile .widget .heading , .selectLocation .widget .heading , .renameDocuments .widget .heading{padding-left:0px !important;}

.createFolder .widget .widget-body .path-label, .uploadFile .widget .widget-body .path-label{padding-top: 5px;}

.manageDocument .widget .widget-body .document-bread-crumb a{text-decoration:underline;}

.manageDocument .widget .widget-head .view-switches{margin-left: 10px; background: none repeat scroll 0% 0% transparent !important; border: medium none;}

.manageDocument .widget .widget-head .view-switches label{float:left;color: #4B4B4B;font-size: 9pt;font-weight: 700;margin:10px 5px 0px 0px;}

.manageDocument .widget .widget-head .view-switches .glyphicons{width:40px !important;}
.manageDocument .widget .widget-head .view-switches #btn_switch_list_view{margin-left:7px;}
.manageDocument .menubar{overflow:visible;float:left;}
.manageDocument .menubar ul, .manageDocument .view-switches ul li{padding:0px;}
.manageDocument .widget .widget-head .view-switches ul{border-bottom:none;}
.manageDocument .view-switches .glyphicons i:before{width:40px !important;font-size:22px !important;padding-top:5px;}
.manageDocument .view-switches .btn-default i:before{color:grey!important;}
.manageDocument .widget .widget-head .view-switches ul i{font-size: 25px;text-align: center;width: 40px;}

.selectLocation .form-horizontal .select-location-label{width:140px;padding-right:5px;}

.renameDocuments .form-horizontal .renamelabel{width:130px;}
.renameDocuments .form-horizontal .renamelabel b{background-color:#FFFF99;}

.inbox .widget #frm_inbox_archive .archive-btn{position:relative;right:175px;}
.inbox .widget #frm_inbox .delete-btn{position:relative;bottom:32px;}

.calendar-legend .span4{margin-left:0px;margin-bottom:5px;line-height:16px;height:50px;}
.calendar-legend .color-box{width:5px;height:5px;padding:5px;border:1px solid #fff;margin:0px;}
.calendar-legend .name-box{position:relative;bottom:16px;left: 20px;}

/** docsTable css for gridview */
#docsTable .gridview{margin-left:0px !important;}
#docsTable .gridview tr.span2{width:130px; margin-left:0px !important;}
#docsTable .gridview td .widget-stats{height:85px;padding:20px 0px;}
#docsTable .gridview td.span2{border:none;}
#docsTable .gridview .gridlabel{position:relative;top:-138px;left:80px;padding:0px;background:none;}
#docsTable .gridview .checker{margin:0px !important;}

/** *************************  */

.marketing-types h3{margin:0px 5px 0px 18px;}
.marketing-types h5{margin: 0px;position:relative;bottom:1px;}

/*
.customer-search-result .search-bar{position:relative;left:200px;}
.customer-search-result .search-bar label{display:inline-block;float:left;}
.customer-search-result .search-bar button{padding:7px 10px 24px 42px;float:left;margin-left:5px;}
*/

.search-bar-server-side #btn_sbmit{
  float: left;
  margin-left: 10px;
  padding: 7px 9px 21px 23px;
}
.search-bar-server-side form{float:right;}
.search-bar-server-side label{float:left;}

#menu #search #customer_id{overflow:scroll;}

/*ui-autocomplete ui-menu ui-widget ui-widget-content ui-corner-all*/
.ui-autocomplete{overflow-y:scroll;border:1px solid #ADAFB1;}
.ui-autocomplete li{border-bottom:1px solid #DDDDDD;}

#view_quotation_customer_detail .customer_detail_right , #view_quotation_customer_detail_inner .customer_detail_right{text-align:right;}

#view_invoice_customer_detail .customer_detail_right , #view_invoice_customer_detail_inner .customer_detail_right{text-align:right;}

#invoice_total_box .invoice_total_right{text-align:right;}

#view_invoice_customer_detail_inner {list-style-type: none;padding-left: 0px;}

#view_invoice_customer_detail_inner .invoice_detail_label {clear: both;float:left;}

#view_invoice_customer_detail_inner .value_right {float:right;}

#view_invoice_customer_detail_inner {margin:0px !important;}

#view_quotation_customer_detail_inner {list-style-type: none;padding-left: 0px;}

#view_quotation_customer_detail_inner .quotation_detail_label {clear: both;float:left;}

#view_quotation_customer_detail_inner .value_right {float:right;}

#view_invoice_customer_detail_inner {margin:0px !important;}

.label-view-quote-invoice-status {
	left: 0px;
    position: absolute;
	width:20px;
	height:185px;
	padding:10px 5px; 
	border-radius:0 5px 5px 0;
	-moz-border-radius: 0 5px 5px 0;
	-webkit-border-radius: 0 5px 5px 0;
	behavior: url(PIE.htc);
	overflow:hidden;
	z-index:999;
}

.label-view-quote-invoice-status .status-label-text{
    text-orientation: sideways-right; direction: ltr;
    -ms-transform: rotate(270deg); /* IE 9 */
    -webkit-transform: rotate(270deg); /* Chrome, Safari, Opera */
    transform: rotate(270deg);
/* Internet Explorer */
filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);    
	white-space:nowrap;
	display:block;
	width:20px;
	font-family: 'Abel',sans-serif;
	font-size:18px;
	font-weight:bold;
	text-decoration:none;
	position:absolute;
	top:120px;
    left: 5px;
	top:40px\9;
    width:100px\9;
}

.label-bg-invoiced {
	background: none repeat scroll 0 0 #C0C0C0 !important;
	border: 1px solid #C0C0C0 !important;
    color: #FFFFFF !important;
	border-radius: 4px 4px 4px 4px;
}
.label-bg-accepted ,  .label-bg-paid {
	background: none repeat scroll 0 0 green !important;
	border: 1px solid green !important;
    color: #FFFFFF !important;
	border-radius: 4px 4px 4px 4px;
}
.label-bg-partial {
	background: none repeat scroll 0 0 orange !important;
	border: 1px solid orange !important;
	border-radius: 4px 4px 4px 4px;
	color: #FFFFFF !important;
}
.label-bg-replied , .label-bg-disputed {
	background: none repeat scroll 0 0 red !important;
    border: 1px solid red !important;
    color: #FFFFFF !important;
    border-radius: 4px 4px 4px 4px;
}
.label-bg-draft {
	background: none repeat scroll 0 0 #DDDDDD;
    border: 1px solid #DDDDDD;
    color: #333333;
    border-radius: 4px 4px 4px 4px;
}
.label-bg-sent {
	background: none repeat scroll 0 0 #0D8ECF;
    border: 1px solid #0D8ECF;
    color: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
}
.label-bg-viewed {
	background: none repeat scroll 0 0 #CD0D74;
    border: 1px solid #CD0D74;
    color: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
}

.ui-autocomplete li{border-bottom:1px solid #DDDDDD;}

.task_extended_label{
	background-color: #FFFFFF;
color: #999999;
cursor: default;
background-color: #FFFFFF;
border: 1px solid #DDDDDD;
border-radius: 15px 15px 15px 15px;
display: inline-block;
padding: 5px 14px;
}

#view_quotation_detail .top_title_hidden{display:none;}
#view_quotation_detail .padding-right-3{padding:4px 25px;}
#view_quotation_customer_detail td{border-top-color: #FFFFFF;}

.preview_business .navbar,.preview_business .form-actions, .preview_business #menu, .preview_business .breadcrumb,
.preview_contact .navbar,.preview_contact .form-actions, .preview_contact #menu, .preview_contact .breadcrumb
{display:none;}


#screen-managebusiness .widget-head .btn-primary {width: 160px;margin-right: 10px;}
#screen-customer .widget-head .btn-primary {width: 160px;margin-right: 10px;}
#screen-subcontractor .widget-head .btn-primary {width: 240px;margin-right: 10px;}
.manageDocument .widget-head .btn-primary {width: 160px;margin-right: 10px;}

#recent-activity ul li span, #my-activity ul li span, .table-activity tbody td span, ul.activity-list li span{font-style:italic;}
#recent-activity .activity-time-ago, #my-activity .activity-time-ago, .table-activity .activity-time-ago, ul.activity-list .activity-time-ago{color:#A7A7A7;font-size:11px;font-style:normal;margin-left: 5px;}
.view-activity-icon a{position:relative;top:-12px;left: 5px;}
#activity-detail .controls{padding-top: 5px;}
#activity-detail .control-label {  font-weight: bold; }
ul.activity-list {margin-left:10px;}
ul.activity-list li{clear:both;line-height:30px; list-style: none;}

#admin_dashboard .alert{clear: both;}
#btn_dashboard_conf{
    height: 40px;
    padding: 10px;
    width: 40px;
    margin-bottom: 10px;
}
#btn_dashboard_conf i::before{font-size: 21px;
left: 3px;
top: 2px;
}

#back-top {
    position: fixed;
    right: 20px;
    bottom: 40px;
}

#back-top a {
    background: #F07918 url(/images/cd-top-arrow.svg) no-repeat center 50%;
    width: 40px;
    height: 40px;
    display: block;
    text-indent: 9999px;

    /* rounded corners */
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;

    /* transition */
    -webkit-transition: 1s;
    -moz-transition: 1s;
    transition: 1s;
}

#frm_customer legend i:before, #frm_business legend i:before, #frm_subcontractor legend i:before, #frm_srch_pagination .customer-search-result legend i:before{
    color:#797979;
    font-size: 15px;
}
#frm_customer legend span, #frm_business legend span, #frm_subcontractor legend span, #frm_srch_pagination .customer-search-result legend span{
    left: 6px;
    padding: 7px !important;
    position: relative;
    top: 2px;
}
#frm_customer legend:hover, #frm_business legend:hover, #frm_subcontractor legend:hover, #frm_srch_pagination .customer-search-result legend{cursor: pointer;}
#frm_customer #fsselect_column_view .row-fluid, #frm_business #fsselect_column_view .row-fluid, #frm_subcontractor #fsselect_column_view .row-fluid, #frm_srch_pagination .customer-search-result .row-fluid{display: none;}
#customer_index_task table{table-layout: fixed;}
#customer_index_task td{word-wrap:break-word;}
/*#admin_dashboard .row-fluid .span6:nth-child(odd){margin-left: 0px !important; }
#admin_dashboard .row-fluid .span6:nth-child(even){float:right !important; }*/
/* For iPad & iPad mini in Landscape & Portrait mode */

.object_ok
{
border: 1px solid green !important; 
}

.object_error
{
border: 1px solid #AC3962 !important; 
}

.display-block{display: block;}

.loginbody #footer_powerby{position: absolute;bottom: 0px;width: 100%;}

/*@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {*/
/*@media only screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-min-device-pixel-ratio: 1){*/
@media only screen and (width: 768px) {
#admin_dashboard .span6 {
  width: 100%;
  margin-left: 0px;
}
#business_index #business_index_quot .table-condensed, #business_index #business_index_invoice .table-condensed{font-size: 9pt;}

}
.catchingwaves .fc-event-title-with-new-css{color: #000000 !important;}

/* Mobile devices */
@media (max-width: 359px) {
	.dashboard .widget .widget-head{
	overflow:hidden;
	width:100%;
	}
}

@media only screen 
and (min-width : 320px) 
and (max-width : 568px) { 
	.dashboard .widget .widget-head{
	overflow:hidden;
	width:100%;
	}
	#customer_index_task_body thead th:last-child, #customer_index_task_body .user_avtar_list{
		display: none;
	}
	#eventCalendar .fc-header-right {display: none;}
	#customer_index_task_body .avatar-text{margin-left: 0px;}
	
	#screen-managebusiness .widget .widget-head {height: 150px !important;}
	#screen-managebusiness .widget .widget-head .btn-primary {margin-bottom: 10px;}
	#screen-managebusiness .widget .widget-head .pull-right{float: left;}
	#screen-managebusiness #frm_business .row-fluid .cb_list_up_row_cs,#screen-managebusiness #frm_business .row-fluid .cb_list_up_row,#screen-managebusiness #frm_business .row-fluid .cb_list_up_row_cu,#screen-managebusiness #frm_business .row-fluid .cb_list_up_row_delete{width: 100%;}
	
	#screen-customer .widget .widget-head {height: 150px !important;}
	#screen-customer .widget .widget-head .btn-primary {margin-bottom: 10px;}
	#screen-customer .widget .widget-head .pull-right{float: left;}
	#screen-customer #frm_customer .row-fluid .cb_list_up_row_cs,#screen-customer #frm_customer .row-fluid .cb_list_up_row,#screen-customer #frm_customer .row-fluid .cb_list_up_row_cu,#screen-customer #frm_customer .row-fluid .cb_list_up_row_delete{width: 100%;}
	
	.manageDocument .widget .widget-head {height: 110px !important;}
	.manageDocument .widget .widget-head .btn-primary {margin-bottom: 10px;}
	.manageDocument .widget .widget-head .pull-right{float: left;}
}


/* Desktop devices */
@media (max-width: 980px) {
#btn_dashboard_conf{display: none;}
}

@media (max-width: 768px) {
    .hidden-768{display: none;}
}

@media (max-width: 480px) {
    .hidden-480{display: none;}
    .loginbody .copyrights{font-size: 11px;}    
    .loginbody .powereby_logo{width: 20%;padding-top: 5px;}    
    .loginbody .powerby_text{padding-top: 0px;}
}

@media (max-width: 320px) {
    .loginbody .copyrights{font-size: 10px;}    
}

@media (max-width: 767px) {
.loginbody #footer_powerby{position: relative;bottom: 0px;width: 100%;}
}

@media print{
    #back-top {
        display: none;
    }
}