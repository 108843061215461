@CHARSET "ISO-8859-1";

.success {
    border-color: #C7F2C8 !important;
    color: #33A333;
}
.error.message {
    background: none repeat scroll 0 0 #FFCCCC;
    border: 1px solid #DD7777;
    color: #EE5555;
}
.message {
    background-color: #FFFFFF;
    border: 1px solid #B8D3E5;
    font-size: 12px;
    height: auto;
    margin: 15px 238px;
    padding: 5px 10px;
    width: 880px;
}
.message ul {
    margin: 0;
    padding: 0;
}
.message li {
    list-style: square inside none;
    margin-left: 15px;
    padding: 0px;
	margin:3px 0px;
}

.form-label.require:before{
	content: '* ';
}

.validationMsgClose {
    color: red;
    left: 75%;
    position: relative;
    text-decoration: none;
}
.validationErrorMsgClose {
	color: red;
    left: 83%;
    position: absolute;
    text-decoration: none;
    top: 13%;
}    
.page-box {
  border-radius: 10px/30px; /* horizontal radius / vertical radius */
  background: #BADA55;
  padding-bottom: 10px;
}
.grid-box {
	position:relative;left:170px;width:1010px;
}
.submit-btn{
	border-radius: 10px/30px;
	padding: 5px;
}
.form-row{
	padding: 5px;
}
.box{
	border-radius: 0px;
}
label.error {
    background: none repeat scroll 0 0 #FFCCCC;
    border: 1px solid #DD7777;
    color: #EE5555;
}
.grey{
text-align:left;background-color:#D3D3D3;
}
.white{
text-align:left;background-color:white;
}
.notes{
text-align:left;background-color:white;
}
.head{
background-color:#3399FF;
}
.end{
background-color:#3399FF;
}

.viewNote{
	background-color: #3399FF;
    padding: 5px;
    height: auto;
}
.viewNoteHeader{
	background-color: #FFFFFF;
    position: relative;
    width: 190px;
    padding: 5px;
}
.viewNoteContent{
	background-color: #D3D3D3;
    height: auto;
    left: 205px;
    position: relative;
    top: -29px;
    width: 785px;
    padding: 5px;
}
.unread{
	color:#000;
	text-decoration: none;
	font-weight: 600;
}
.read{
	color:#000;
	text-decoration: none;
}
.forwarded{
	color:#000;
	text-decoration: none;
}
.notesCustomerSelection{
	/*background: none repeat scroll 0 0 #444444;*/
	background: none repeat scroll 0 0 #FFFFFF;
    border: 1px solid #000000;
    /*color: #FFFFFF;*/
    color: #444444;
    font-size: 14px;
    width: 423px;
    text-align:center;
	padding: 5px;
	line-height: 1;
	border: 0;
	border-radius: 0;
	height: 34px;
}
.viewUpcomingEvents{
	background-color: #F07918;
    height: auto;
    overflow: hidden;
    padding: 5px;
    text-align: center;
    width: 300px;
}
.eventDetails{
	background-color: #FFFFFF;
    padding: 5px;
}
.eventViewTitle{
	background-color: #D7DADC;
    padding: 5px;
}
.upcomingEvents{
	background-color: #D7DADC;
    height: auto;
    left: 38px;
    position: absolute;
    top: 60px;
    width: 310px;
}

/* for calendar */
#eventCalendar {
	left: 600px;
    position: absolute;
    top: 8px;
    width: 400px;
}

#calendar {
	width: auto;
	margin: 0 auto;
}
	

.eventshow {
  position: relative;
  top: 0px;
  left: 1px;
  width:90%;
  height:30px;
  opacity:0.8;
  filter:alpha(opacity=80);
  color: #ffffff;
  background-color: #000;
  padding:10px 25px 10px 25px;
  z-index:999;
  border-color: #fff;
  border-radius:5px; 
  -moz-border-radius:5px;
  -webkit-border-radius:5px;
}